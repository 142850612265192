export default {
    methods: {
        /* Files identification */
        sourceFile(file) {
            const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
            if(this.isEmbeddRendable(file)) return file.files_url
            if(this.isTextRendable(file)) return file.files_content
            if(file.files_url.substring(file.files_url.length - 4) == '.pdf' && isIOS)
                return 'https://drive.google.com/viewerng/viewer?embedded=true&url='+file.files_s3_url
            return file.files_s3_url
        },
        isIframeRendable(file) {
            const compatible_types = ['application']
            return compatible_types.indexOf(file.files_mimetype) > -1
        },
        isImgRendable(file) {
            return file.files_mimetype == 'image'
        },
        isAudioRendable(file) {
            return file.files_mimetype == 'audio'
        },
        isVideoRendable(file) {
            return file.files_mimetype == 'video'
        },
        isEmbeddRendable(file) {
            return file.files_mimetype == 'embedded'
        },
        isTextRendable(file) {
            return file.files_mimetype == 'text'
        }
    }
}